import React from 'react';
import { graphql } from 'gatsby';
import { SliceMaster } from '../components';

const Page = (props) => {
  const { data } = props;
  const page = data && data.page;

  return (
    <SliceMaster slices={page.slices || []} />
  );
};

export const query = graphql`
query PageQuery($id: String!) {
  page: sanityPage(id: { eq: $id }) {
    slices {
      ... on SanityAboutSlice {
        id: _key
        type: _type
        featuredText
        body: _rawBody
        callToAction: call_to_action {
          label
          href
          blank
        }
      }
      ... on SanityManifestoSlice {
        id: _key
        type: _type
        title
        featuredText
        body: _rawBody
      }
      ... on SanityProfilesSlice {
        id: _key
        type: _type
        title
        greyscaleFilter: greyscale_filter
        profiles {
          id: _key
          name
          bio
          photo {
            ...ImageWithPreview
          }
        }
      }
      ... on SanityVideoStreamSlice {
        id: _key
        type: _type
        playlist {
          id: _key
          slug {
            current
          }
          description
          speaker
          title
          thumbnail: poster_new {
            ...ImageWithPreview
          }
          posterFixed: poster_new {
            ...Image
          }
          mobilePosterFixed: mobile_poster_new {
            ...ImageWithPreview
          }
          video {
            rawAsset: _rawAsset(resolveReferences: {maxDepth: 10})
            asset {
              playbackId
            }
          }
          videoDuration: video_duration
        }
      }
    }
  }
}
`;


export default Page;
